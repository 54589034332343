
import Geolocator from '@/lib/geolocator'
import LoadingSpinner from '@/components/loading_spinner'
import { formatted_datetime } from '@/lib/helpers'
import QuantityControl from '@/components/quantity_control'

export default {

  components: {
    LoadingSpinner,
    QuantityControl
  }

  data: -> {
    map_open: false
    map_instance: null
    specialsPDF: false
    range: 5
    location: {
      latidue: -33.8999547
      longitude: 18.5701029
    }
  }

  computed: {
    banners: ->
      @$store.state.banners.all

    loading: ->
      @$store.state.leaflets.loading

    leaflets: ->
      @$store.state.leaflets.all

    map_position: ->
      {
        lat: @location.latitude,
        lng: @location.longitude
      }
  }

  methods: {
    formatted_date: (datetime) ->
      formatted_datetime(datetime, 'dd LLL y')

    open_map: ->
      @render_map()
      @map_open = true

    close_map: ->
      @fetch_items()
      window.localStorage.setItem('specials_radius', @range)
      @map_open = false

    render_map: ->
      map_element = window.document.getElementById("map")
      @map_instance = new window.google.maps.Map(map_element, {
        zoom: 10,
        center: @map_position,
        disableDefaultUI: true
      })
      @draw_circle()
      @draw_marker()

    draw_circle: ->
      @radius_circle = new google.maps.Circle({
        map: @map_instance,
        center: @map_position,
        strokeOpacity: 0,
        fillColor: @$vuetify.theme.currentTheme.primary,
        fillOpacity: 0.35,
      })
      @adjust_circle()

    adjust_circle: ->
      @radius_circle.setOptions({
        radius: @range * 1000
      })

    draw_marker: ->
      @map_marker = new window.google.maps.Marker({
        position: @map_position,
        map: @map_instance
      })

    fetch_items: ->
      @$store.dispatch('leaflets/fetch', {
        lat: @location.latitude
        lon: @location.longitude
        radius: @range
      })

    get_geolocation: ->
      new Geolocator(@set_location, @geolocate_error).locate()

    set_location: (location) ->
      @location = location

    geolocate_error: (error) ->
      console.error('geolocation_fail', error)
      @set_location(@$store.getters['application/default_position'])
  }

  watch: {
    range: ->
      @adjust_circle() if @radius_circle

    location: ->
      @fetch_items('leaflets/fetch', @location)
  }

  created: ->
    @$store.dispatch('banners/fetch')
    @range = window.localStorage.getItem('specials_radius') || 5
    @get_geolocation()
    @$store.commit('layout/page_nav', {title: @$t("leaflets_page_header"), back_target: '/'})
}
